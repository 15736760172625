<template>
  <div>
    <div v-if="showAvailableCampaigns" class="flex flex-col md:flex-row gap-4 mb-6">
      <Button
        id="button-my-campaigns"
        :kind="myCampaigns ? 'primary' : 'secondary'"
        label="My campaigns"
        :grow="windowWidth <= 768"
        @clicked="myCampaigns = true"
      />

      <Button
        id="button-available-campaigns"
        :kind="!myCampaigns ? 'primary' : 'secondary'"
        label="Available campaigns"
        :grow="windowWidth <= 768"
        @clicked="myCampaigns = false"
      />
    </div>

    <div v-if="myCampaigns">
      <div v-if="campaigns && campaigns.length" class="campaign__list">
        <div v-for="campaign of campaigns" :key="campaign._id">
          <Tile class="h-full rounded campaign-tile" border="none">
            <div class="campaign-tile__thumbnail">
              <img
                v-if="campaign.thumbnail"
                v-lazy="campaign.thumbnail"
                :alt="`${campaign.title} thumbnail`"
                class="campaign__thumbnail"
              />

              <img
                v-else
                v-lazy="
                  `https://storage.googleapis.com/overlay-sources/9daac7f7-5a05-4cfd-9035-5c14b39bab4anetwrk_campaign_fallback.jpg`
                "
                :alt="`${campaign.title} thumbnail`"
                class="campaign__thumbnail"
              />
            </div>

            <div class="w-full campaign-tile__body">
              <div class="flex items-center">
                <p class="paragraph">
                  {{ campaign.date | moment("Do MMM YYYY") }}
                </p>

                <Label v-if="!campaign.active" class="ml-4" message="Finished" color="transparent" />
              </div>

              <Title class="mb-6" :title="campaign.title" size="small" />

              <div class="flex gap-4 items-center">
                <Button
                  :id="`button-show-campaign-stats-${campaign._id}`"
                  kind="secondary"
                  :grow="windowWidth <= 768"
                  label="Statistics"
                  @clicked="switchCampaignStatistics(true, campaign._id)"
                />

                <Button
                  :id="`button-show-campaign-details-${campaign._id}`"
                  kind="link"
                  label="Description"
                  :grow="windowWidth <= 768"
                  @clicked="switchCampaignDetails(true, campaign._id)"
                />
              </div>
            </div>
          </Tile>
        </div>
      </div>

      <div v-else>
        <p>
          No joined campaigns.
          <span v-if="showAvailableCampaigns">
            Click on "Available campaigns" to see which campaigns you can join. Join the
            <a href="https://discord.gg/vMxkTwdgPv" class="underline">NETWRK Discord</a>
            to get notified when new campaigns are available.
          </span>
        </p>
      </div>
    </div>

    <div v-else-if="showAvailableCampaigns">
      <div v-if="!checkIfBroadcastingLanguage">
        <div class="flex w-full max-w-lg" style="place-items: flex-end">
          <AutoComplete
            :data="languageList"
            label="Please select your broadcasting language"
            :value="broadcastingLanguage"
            @selectedItem="setBroadcastingLanguage"
          />

          <Button
            id="button-save-language"
            kind="primary"
            class="ml-4"
            label="Save"
            @clicked="saveBroadcastingLanguage"
          />
        </div>
      </div>

      <div v-else-if="filterAvailableCampaigns && filterAvailableCampaigns.length" class="campaign__list">
        <div v-for="campaign of filterAvailableCampaigns" :key="campaign._id">
          <Tile class="h-full rounded campaign-tile">
            <router-link :to="''">
              <div class="campaign-tile__thumbnail">
                <img
                  v-if="campaign.thumbnail"
                  v-lazy="campaign.thumbnail"
                  :alt="`${campaign.title} thumbnail`"
                  class="campaign__thumbnail"
                />

                <img
                  v-else
                  v-lazy="
                    `https://storage.googleapis.com/overlay-sources/9daac7f7-5a05-4cfd-9035-5c14b39bab4anetwrk_campaign_fallback.jpg`
                  "
                  :alt="`${campaign.title} thumbnail`"
                  class="campaign__thumbnail"
                />
              </div>

              <div class="campaign-tile__body">
                <span class="paragraph">
                  {{ campaign.date | moment("Do MMM YYYY") }}
                </span>

                <Title class="mb-6" :title="campaign.title" size="small" />

                <div class="flex gap-4 items-center">
                  <Button
                    :id="`button-${checkIfPendingInvite(campaign._id) ? 'request' : 'cancel'}-join-campaign`"
                    kind="secondary"
                    :disabled="checkIfPendingInvite(campaign._id)"
                    :label="checkIfPendingInvite(campaign._id) ? 'Pending' : 'Request to join'"
                    @clicked="requestToJoinCampaign(campaign._id)"
                  />

                  <Button
                    :id="`button-show-campaign-details-${campaign._id}`"
                    kind="link"
                    label="Description"
                    @clicked="switchCampaignDetails(true, campaign._id)"
                  />
                </div>
              </div>
            </router-link>
          </Tile>
        </div>
      </div>

      <div v-else>
        <p>
          No available campaigns. Join the
          <a href="https://discord.gg/vMxkTwdgPv" class="underline">NETWRK Discord</a>
          to get notified when new campaigns are available.
        </p>
      </div>
    </div>

    <CampaignDetails
      v-if="showCampaignDetailsModal"
      :campaign="currentCampaign"
      @closeModal="switchCampaignDetails(false)"
    />

    <CampaignStatistics
      v-if="showCampaignStatisticsModal"
      :campaign="currentCampaign"
      @closeModal="switchCampaignStatistics(false)"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { Title, Button, Tile } from "cavea-design-system";
import log from "@/lib/log";

export default {
  name: "StreamerCampaigns",

  components: {
    Title,
    Tile,
    Label: () => import("@/components/Label"),
    Button,
    CampaignDetails: () => import("@/components/streamer/CampaignDetails"),
    CampaignStatistics: () => import("@/components/streamer/CampaignStatistics"),
    AutoComplete: () => import("@/components/inputs/AutoComplete"),
  },

  props: {
    campaigns: {
      type: Array,
      default: null,
    },
    showAvailableCampaigns: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      myCampaigns: true,
      showCampaignDetailsModal: false,
      showCampaignStatisticsModal: false,
      currentCampaign: null,
      languageList: [],
      broadcastingLanguage: null,
      windowWidth: 0,
    };
  },

  computed: {
    ...mapGetters(["getUserInfo", "getCampaignInvites", "getAvailableCampaigns"]),

    checkIfBroadcastingLanguage() {
      if (this.getUserInfo?.info?.broadcastingLanguage?.length > 0) {
        return true;
      }

      return false;
    },

    filterAvailableCampaigns() {
      if (this.getAvailableCampaigns && this.getCampaignInvites) {
        const rejectedRequests = this.getCampaignInvites
          .filter((i) => i.contentCreatorAccepted === false || i.campaignAdminAccepted === false)
          .map((i) => i.campaignId);

        return this.getAvailableCampaigns.filter((c) => rejectedRequests.findIndex((r) => r === c._id) === -1);
      }

      return null;
    },
  },

  created() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    this.fetchLanguageList();
  },

  methods: {
    ...mapActions(["fetchCampaignInvites", "refreshUserInfo"]),

    /**
     * @param {boolean} show
     * @param {string} campaignId - optional
     */
    switchCampaignDetails(show, campaignId = null) {
      if (show) {
        this.showCampaignDetailsModal = true;
        this.showCampaignStatisticsModal = false;

        if (this.myCampaigns) {
          this.currentCampaign = this.campaigns.find((campaign) => campaign._id === campaignId);
        } else {
          this.currentCampaign = this.getAvailableCampaigns.find((campaign) => campaign._id === campaignId);
        }
      } else {
        this.showCampaignDetailsModal = false;
      }
    },

    /**
     * @param {boolean} show
     * @param {string} campaignId - optional
     */
    switchCampaignStatistics(show, campaignId = null) {
      if (show) {
        this.showCampaignStatisticsModal = true;
        this.showCampaignDetailsModal = false;
        this.currentCampaign = this.campaigns.find((campaign) => campaign._id === campaignId);
      } else {
        this.showCampaignStatisticsModal = false;
      }
    },

    /**
     * @summary send request for streamer to join campaign
     * @param {string} campaignId
     */
    async requestToJoinCampaign(campaignId) {
      log("requestToJoinCampaign", campaignId);

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/ad-campaign/request-to-join/${campaignId}/${this.getUserInfo._id}`;
      const response = await axios
        .post(URL)
        .then((res) => res.data)
        .catch((err) => {
          console.error("requestToJoinCampaign error", err);
          return err;
        });

      log("requestToJoinCampaign", response);

      this.fetchCampaignInvites();
    },

    /**
     * @param {string} campaignId
     */
    checkIfPendingInvite(campaignId) {
      if (this.getCampaignInvites.findIndex((c) => c.campaignId === campaignId) !== -1) {
        return true;
      }

      return false;
    },

    /**
     * @param {string} value
     */
    setBroadcastingLanguage(value) {
      this.broadcastingLanguage = value;
    },

    async saveBroadcastingLanguage() {
      log("saveBroadcastingLanguage");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/content-creator/settings/language`;

      const response = await axios
        .post(URL, {
          contentCreatorId: this.getUserInfo._id,
          broadcastingLanguage: this.broadcastingLanguage,
        })
        .then((res) => res.data)
        .catch((err) => console.error("saveBroadcastingLanguage error", err));

      log("saveBroadcastingLanguage", response);

      this.refreshUserInfo(this.getUserInfo._id);
    },

    async fetchLanguageList() {
      log("fetchLanguageList");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/get-languages`;

      this.languageList = await axios
        .get(URL)
        .then((res) => res.data.languages)
        .catch((err) => {
          console.error("fetchLanguageList error", err);
          return [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/helpers";

.campaign__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;

  .campaign-tile {
    &:hover {
      .campaign-tile__thumbnail > img {
        transform: scale(1.1);
        transition: 0.3s;
      }
    }

    .campaign-tile__body {
      padding: 1rem;
    }

    .campaign-tile__thumbnail {
      display: flex;
      width: 100%;
      height: 200px;
      align-items: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;
      justify-content: center;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .campaign-tile__actions {
      .button {
        justify-content: center;
        width: 50%;
      }
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.buttons {
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;

    button {
      width: 100%;
    }
  }
}
</style>
